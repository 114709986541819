import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { featuresService } from '../../../../../../../App';
import { SportTypes } from '../../../../../../../constants/sport.types';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import MatchesByTeamIdComponent from '../../../partials/matches/matches-by-team-id.component';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import StakesModel from '../models/stakes-widget.model';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: StakesModel;
	isValid: boolean;
};

export default class FootballStakesWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballStakes,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
				props.block,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: StakesModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			StakesModel.builder(this.state.widgetModel).withTeam(team).withMatch(new MatchModel()).withBookmakers([]).build(),
		);
	};

	onMatchChange = (match: MatchModel) => {
		this.updateWidgetModelState(StakesModel.builder(this.state.widgetModel).withMatch(match).withBookmakers([]).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[] | null) => {
		this.updateWidgetModelState(StakesModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(StakesModel.builder(this.state.widgetModel).withAmount(parseInt(event.target.value)).build());
	};

	onFixedAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(StakesModel.builder(this.state.widgetModel).withFixedAmount(event.target.checked).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, StakesModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<div data-qa='football-odds-block-edit'>
				<Row className='mb-2'>
					<Col>
						<TeamSelectComponent
							isMulti={false}
							isBlocky
							isValid={isValid}
							selectedTeam={widgetModel.team ? widgetModel.team : {}}
							onTeamSelect={(team: TeamModel) => this.onTeamChange(team)}
							t={t}
							isClearable
							isRequired={true}
							searchByKeyword={true}
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
						/>
					</Col>
				</Row>
				{widgetModel.team && widgetModel.team.id && (
					<Row className='mb-2'>
						<Col>
							<MatchesByTeamIdComponent
								selectedMatch={widgetModel && widgetModel.match ? widgetModel.match : new MatchModel()}
								onMatchSelect={this.onMatchChange}
								marketValueType='DECIMAL'
								teamId={widgetModel.team && widgetModel.team.id ? widgetModel.team.id : null}
								isValid={isValid}
								isRequired={false}
								limit={'10'}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH}`}
							/>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && widgetModel.match && widgetModel.match.id && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isMulti={false}
								isValid={isValid}
								showAllBookmakers={true}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={t}
								valueList={widgetModel.bookmakers || []}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.FOOTBALL}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								mandatory={false}
							/>
						</Col>
					</Row>
				)}
				<Row className='mb-2'>
					<Col>
						<FormGroup>
							<Label>{t('amount')}:</Label>
							<span className='text-danger'>*</span>
							<Input
								type='number'
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.AMOUNT}`}
								className='form-control'
								value={widgetModel.amount}
								max={1000000}
								min={1}
								onChange={this.onAmountChange}
							/>
						</FormGroup>
						<ErrorHandler
							t={t}
							arePropsValid={!isNaN(widgetModel.amount) && widgetModel.amount > 0}
							isValueSelected={widgetModel.amount !== null && widgetModel.amount !== undefined}
							errorMessage='must_be_a_positive_integer'
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup check className='radio'>
							<Input
								className='form-check-input'
								type='checkbox'
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.FIXED_AMOUNT}`}
								name='radios'
								onChange={this.onFixedAmountChange}
								checked={widgetModel.fixedAmount || false}
								disabled
							/>
							<Label
								htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.FIXED_AMOUNT}`}
								check
								className='form-check-label'
								for={`fixed-amount-checkbox-${this.props.block.id}`}
							>
								{t('fixed_amount')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
